<template>
  <v-list
    expand
    nav
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item
        v-else
        :key="`item-${i}`"
        :item="item"
        @click="clickHandler(item)"
      />
    </template>
  </v-list>
</template>

<script>
  import { authComputed } from '@/store/helpers'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'DefaultList',

    components: {
      DefaultListGroup: () => import('./ListGroup'),
      DefaultListItem: () => import('./ListItem'),
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },

    computed: {
      ...authComputed,
      ...sync('app', [
        'drawer',
      ]),
    },
    methods: {
      // custom dikia mou gia na kalei callbacks
      clickHandler (item) {
        if (item.onClick === 'showLogin') {
          this.$store.commit('login/SET_SHOW_LOGIN', true)
          if (window.innerWidth < this.$vuetify.breakpoint.mobileBreakpoint) {
            this.drawer = false
          }
        } else if (item.onClick === 'logout') {
          this.$store.dispatch('auth/logout')
        }
      },
    },
  }
</script>
